@import '../../../scss/theme-bootstrap';

.my-account-profile-links-v1 a {
  display: block;
  font-size: 22px;
  font-family: $base-font-family;
  margin-bottom: 12px;
}

.my-account-profile-links-v1 a.gray {
  color: #bdbdbd;
}
